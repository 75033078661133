<template>
  <div>
    <b-card no-body>
      <!-- <div class="m-2"><b-form @submit.prevent><b-row><b-col md="4"><b-button id="toggle-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-prevent-closing variant="outline-primary" >add</b-button> </b-col> </b-row> </b-form> </div> -->
      <b-table
        ref="refDepositChannelTable"
        :items="depositList"
        responsive
        :fields="tableColumns"
        primary-key="transactionId"
        show-empty
        empty-text="No matching records found"
        class="position-relative withdraw-table"
      >
        <template #cell(display_order)="data">
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
          >
            <template #button-content>
              <feather-icon
                class="align-middle display-order"
                icon="AlignJustifyIcon"
                size="28"
                color="#6b6878"
              />
            </template>
            <b-dropdown-item @click="upOneItem(data.item)">
              <feather-icon
                class="align-middle display-order"
                icon="ArrowUpIcon"
                size="22"
              />
              <span>Up one position</span>
            </b-dropdown-item>
            <b-dropdown-item @click="downOneItem(data.item)">
              <feather-icon
                class="align-middle display-order"
                icon="ArrowDownIcon"
                size="22"
              />
              <span> Down one position </span>
            </b-dropdown-item>
            <b-dropdown-item @click="upItemTop(data.item)">
              <img
                src="@/assets/images/settings/to_top.png"
                alt="to_top"
                class="display-order-icon"
              >
              <span> Up to top </span>
            </b-dropdown-item>
            <b-dropdown-item @click="downItemBottom(data.item)">
              <img
                src="@/assets/images/settings/to_bottom.png"
                alt="to_bottom"
                class="display-order-icon"
              >
              <span> Down to bottom </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <!-- Column: channelType -->
        <template #cell(channelType)="data">
          {{ channelTypes[data.value] }}
        </template>
        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          {{ data.value ? updateDate(data.value, timeZone) : '' }}
        </template>
        <!-- Column: updatedAt -->
        <template #cell(updatedAt)="data">
          {{ data.value ? updateDate(data.value, timeZone) : '' }}
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          {{ statusOptions[data.value] }}
        </template>
        <template #cell(edit)="data">
          <div
            v-hasBtn="`deposit_channel`"
            class="d-flex"
          >
            <b-button
              class="mr-1"
              :variant="data.item.status == 1?'outline-success':'outline-secondary'"
              @click="switchChannel(data.item, true)"
            >
              {{ data.item.status == 1 ? 'OFF':'&nbsp;ON&nbsp;' }}
            </b-button>
            <b-button
              :variant="data.item.status == 1?'outline-success':'outline-secondary'"
              @click="editChannel(data.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePagination"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- modal -->
    <b-modal
      ref="add-deposit-modal"
      title="Edit Deposit Channel Settings"
      size="lg"
      ok-only
      ok-title="Confirm"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        class="mt-2"
        @submit.stop.prevent="handleSubmit"
      >
        <!-- <b-form-group invalid-feedback="channelType is required">
          <div class="d-flex justify-content-between">
            <label for="label-select">channelType</label>
          </div>
          <v-select id="label-select"
                    v-model="selected"
                    :options="channelTypeOptions"
                    class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
                    label="title">
          </v-select>
        </b-form-group> -->
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">CHANNEL NAME</label>
              </div>
              <b-form-input
                v-model="depositModal.channelName"
                autocomplete="off"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">CODE</label>
              </div>
              <b-form-input
                v-model="depositModal.code"
                autocomplete="off"
                disabled
              />
            </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">COUNTRY</label>
              </div>
              <b-form-input
                v-model="depositModal.iso_code"
                autocomplete="off"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">FEE</label>
              </div>
              <b-form-input
                v-model="depositModal.fee"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">MAX DEPOSIT AMOUNT</label>
              </div>
              <b-form-input
                v-model="depositModal.amount_max"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">MIN DEPOSIT AMOUNT</label>
              </div>
              <b-form-input
                v-model="depositModal.amount_min"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </div>
</template>
<script>
import {
  ref, computed, onUnmounted, getCurrentInstance,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  // BForm,
  // BFormSelect,
  // BListGroup,
  // BListGroupItem,
  // BTooltip,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { showToast, updateDate } from '@/libs/utils'
import store from '@/store'
import settingsStoreModule from './settingsStoreModule'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BModal,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    // BForm,
    // BFormSelect,
    // BListGroup,
    // BListGroupItem,
    // BTooltip,
    // vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {
    ...mapStateWallet(['timeZone']),
  },
  watch: {
    timeZone() {
      // this.showDepositChannel()
    },
  },
  mounted() {
    this.showDepositChannel()
  },
  setup() {
    const { proxy } = getCurrentInstance()
    // 注册vuex
    const SettingsStoreModuleName = 'settings'
    // Register module
    if (!store.hasModule(SettingsStoreModuleName)) { store.registerModule(SettingsStoreModuleName, settingsStoreModule) }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SettingsStoreModuleName)) { store.unregisterModule(SettingsStoreModuleName) }
    })
    const refDepositChannelTable = ref(null)
    const depositList = ref([])
    const tableColumns = [
      { key: 'display_order', label: 'display order' },
      { key: 'channelName', label: 'channel name' },
      { key: 'code', label: 'code' },
      { key: 'iso_code', label: 'country' },
      { key: 'fee', label: 'FEE' },
      { key: 'amount_max', label: 'MAX DEPOSIT AMOUNT' },
      { key: 'amount_min', label: 'MIN DEPOSIT AMOUNT' },
      { key: 'createdAt', label: 'CREATE TIME' },
      { key: 'updatedAt', label: 'UPDATE TIME' },
      { key: 'status', label: 'STATUS' },
      { key: 'edit', label: 'operate' },
    ]
    const perPage = ref(10000)
    const totalList = ref(0)
    const currentPage = ref(1)
    const channelTypeOptions = [
      { title: 'Withdraw', value: '1' },
      { title: 'Deposit', value: '2' },
    ]
    const statusOptions = { 1: 'Active', 2: 'Non-active' }
    const channelTypes = { 1: 'Withdraw', 2: 'Deposit' }
    const channelName = ref('')
    // let selected = ref({ title: "Withdraw", value: "1" });
    const dataMeta = computed(() => {
      const localItemsCount = refDepositChannelTable.value
        ? refDepositChannelTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalList.value,
      }
    })

    const depositModal = ref({
      channelName: '',
      code: '',
      iso_code: '',
      fee: '',
      amount_max: '',
      amount_min: '',
    })
    // eslint-disable-next-line prefer-const
    let currentItem = ref(null)
    // func
    const editChannel = item => {
      currentItem.value = item
      const depositArray = ['channelName', 'code', 'iso_code', 'fee', 'amount_max', 'amount_min']
      depositArray.forEach(key => {
        depositModal.value[key] = item[key]
      })
      proxy.$refs['add-deposit-modal'].show()
    }

    const showDepositChannel = () => {
      proxy.$showLoading()
      const res = store.dispatch('settings/showDepositChannel', {
        pageNo: currentPage.value,
        pageSize: perPage.value,
        channelType: 2,
      })
      res
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code == 200) {
            const { list, page } = response.data.data
            depositList.value = list
            totalList.value = page.tatalCount
            // showToast(proxy, "success", response.data.message);
          } else {
            showToast(proxy, 'warning', response.data.message)
          }
        })
        .catch(error => {
          proxy.$hideLoading()
          showToast(proxy, 'warning', error.message)
        })
    }

    const switchChannel = (item, flag) => {
      proxy.$showLoading()
      let curStatus
      if (!flag) {
        curStatus = item.status
      } else {
        curStatus = item.status === 1 ? 2 : 1
      }
      const res = store.dispatch('settings/updateDepositChannel', {
        iso_code: item.iso_code,
        code: item.code,
        status: curStatus,
        fee: depositModal.value.fee,
        amount_max: depositModal.value.amount_max,
        amount_min: depositModal.value.amount_min,
        channelType: 2, // 1，withdraw;2,deposit
        id: item.id,
      })
      res
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code === 200) {
            currentItem.value = null
            depositModal.value.fee = null
            depositModal.value.amount_max = null
            depositModal.value.amount_min = null
            proxy.$refs['add-deposit-modal'].hide()
            showDepositChannel()
            showToast(proxy, 'success', 'success')
          } else {
            showToast(proxy, 'warning', response.data.message)
          }
        })
        .catch(error => {
          proxy.$hideLoading()
          showToast(proxy, 'warning', error.message)
        })
    }

    const handleSubmit = () => {
      proxy.$showLoading()
      const res = store.dispatch('settings/addDepositChannel', {
        channelType: 2, // 1，withdraw;2,deposit
        channelName: channelName.value,
      })
      res
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code == 200) {
            showToast(proxy, 'success', response.data.message)
            showDepositChannel()
          } else {
            showToast(proxy, 'warning', response.data.message)
          }
        })
        .catch(error => {
          proxy.$hideLoading()
          showToast(proxy, 'warning', error.message)
        })
      proxy.$nextTick(() => {
        proxy.$refs['add-deposit-modal'].hide()
      })
    }

    const resetModal = () => {
      depositModal.value.channelName = ''
      depositModal.value.code = ''
      depositModal.value.iso_code = ''
      depositModal.value.fee = ''
      depositModal.value.amount_max = ''
      depositModal.value.amount_min = ''
    }

    const handleOk = bvModalEvt => {
      currentPage.value = 1
      bvModalEvt.preventDefault()
      // 新增
      // proxy.handleSubmit()
      // 编辑
      proxy.switchChannel(currentItem.value, false)
    }

    const changePagination = page => {
      currentPage.value = page
      showDepositChannel()
    }

    const sortChannelsList = () => {
      const params = []
      depositList.value.forEach((goods, index) => {
        params.push({ id: `${goods.id}`, sort: `${index + 1}` })
      })
      const res = store.dispatch('settings/sortChannel', { data: params })
      res
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code === 200) {
            showToast(proxy, 'success', 'sort success')
            showDepositChannel()
          } else {
            showToast(proxy, 'warning', response.data.message)
          }
        })
        .catch(error => {
          proxy.$hideLoading()
          showDepositChannel()
          showToast(proxy, 'warning', error.message)
        })
    }

    const upOneItem = item => {
      const goodIndex = depositList.value.findIndex(value => value.id === item.id)
      if (goodIndex === 0) {
        showToast(proxy, 'warning', "Can't be moved upwards.")
        return
      }
      depositList.value.splice(goodIndex, 1)
      depositList.value.splice(goodIndex - 1, 0, item)
      sortChannelsList()
    }

    const downOneItem = item => {
      const goodIndex = depositList.value.findIndex(value => value.id === item.id)
      if (goodIndex === (depositList.value.length - 1)) {
        showToast(proxy, 'warning', "Can't be moved downwards.")
        return
      }
      depositList.value.splice(goodIndex, 1)
      depositList.value.splice(goodIndex + 1, 0, item)
      sortChannelsList()
    }

    const upItemTop = item => {
      const goodIndex = depositList.value.findIndex(value => value.id === item.id)
      if (goodIndex === 0) {
        showToast(proxy, 'warning', "Can't be moved upwards.")
        return
      }
      depositList.value.splice(goodIndex, 1)
      depositList.value.splice(0, 0, item)
      sortChannelsList()
    }

    const downItemBottom = item => {
      const goodIndex = depositList.value.findIndex(value => value.id === item.id)
      if (goodIndex === (depositList.value.length - 1)) {
        showToast(proxy, 'warning', "Can't be moved downwards.")
        return
      }
      const goodsLen = depositList.value.length
      depositList.value.splice(goodIndex, 1)
      depositList.value.splice(goodsLen, 0, item)
      sortChannelsList()
    }

    return {
      refDepositChannelTable,
      depositList,
      tableColumns,
      dataMeta,
      perPage,
      totalList,
      currentPage,
      channelTypeOptions,
      channelName,
      // selected,
      channelTypes,
      updateDate,
      statusOptions,

      depositModal,
      currentItem,
      // func
      editChannel,
      switchChannel,
      showDepositChannel,
      handleSubmit,
      resetModal,
      handleOk,
      changePagination,
      upOneItem,
      downOneItem,
      upItemTop,
      downItemBottom,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.invoice-filter-select {
  min-width: 190px;
  width: 100%;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.deposit-channel-status {
  line-height: 24px;
}
// 排序
.display-order {
  cursor: pointer;
}
.display-order-icon {
  width: 18px;
  height: auto;
  margin: 0 2px;
}
</style>
